<template>
  <div class="stripe-main">
    <div class="content">
      <div
        class="button"
        :class="{
          loading
        }"
        @click="handleSubscribe"
      >
        Pay with credit card
      </div>
    </div>
  </div>
</template>

<script>
import {
  isAndroid,
  isIOS
} from '@/utils'
import { createOrderPayermax } from '@/api/pay.js'
import { mapMutations } from 'vuex'
export default {
  name: 'SUBSCRIBE',
  data () {
    return {
      loading: false
    }
  },
  created () {
    const token = this.$route.query.token
    this.SET_TOKEN(token)
    if (isAndroid) {
      this.SET_AKEY('ANDROID')
      this.SET_gaid(this.$route.query.gaid)
    } else if (isIOS) {
      this.SET_AKEY('IOS')
      this.SET_idfa(this.$route.query.idfa)
      this.SET_idfv(this.$route.query.idfv)
    }
    if (this.$route.query.flavor === 'netboom') {
      this.SET_APPKEY('8a5b6e9475d9b50db3e1ff8b6db15693')
    } else if (this.$route.query.flavor === 'NetBomb') {
      this.SET_APPKEY('364de180d9b61df9394f019bedeb7267')
    } else if (this.$route.query.flavor === 'netboomVR') {
      this.SET_APPKEY('86b28c96a29055f46686740f335e2d18')
    } else if (this.$route.query.flavor === 'NetBoom') {
      this.SET_APPKEY('b131213a82f1dd05a5a9f2bd8c5aeb42')
    } else {
      this.SET_APPKEY('')
    }
    // NetBomb
    setTimeout(() => {
      if (this.$route.query.isSub && this.$route.query.goodsId) {
        this.handleSubscribe()
      }
    }, 1000)
  },
  methods: {
    ...mapMutations('service', ['SET_TOKEN', 'SET_APPKEY', 'SET_AKEY', 'SET_idfa', 'SET_gaid', 'SET_idfv']),
    handleSubscribe () {
      if (this.loading) return
      this.loading = true
      // let app
      // if (this.$route.query.flavor === 'NetBomb') {
      //   app = 'NetBomb'
      // } else {
      //   app = 'netboom'
      // }
      // createOrder({
      //   channel: 4,
      //   chargeScene: '-1',
      //   goods: [{ goodsId: this.$route.query.goodsId, number: 1 }]
      // })
      //   .then((res) => {
      //     if (res.code === 200) {
      //       if (this.$route.query.isSub === 'true') {
      //         createSubscribeSession({
      //           orderId: res.data.orderIdStr,
      //           successUrl: location.protocol + '//' + location.host + '/pay/subscribe/success?orderId=' + res.data.orderIdStr + '&issuccess=1',
      //           cancelUrl: location.protocol + '//' + location.host + '/pay/subscribe/success?orderId=' + res.data.orderIdStr + '&issuccess=2',
      //           app: app
      //         })
      //           .then((res) => {
      //             if (res.code === 200) {
      //               window.open(res.data.url)
      //             }
      //           })
      //           .finally(() => {
      //             this.loading = false
      //           })
      //       } else {
      //         stripePaycodes({
      //           orderId: res.data.orderIdStr,
      //           successUrl: location.protocol + '//' + location.host + '/pay/subscribe/success?orderId=' + res.data.orderIdStr + '&issuccess=1',
      //           cancelUrl: location.protocol + '//' + location.host + '/pay/subscribe/success?orderId=' + res.data.orderIdStr + '&issuccess=2',
      //           app: app
      //         })
      //           .then(res => {
      //             if (res.code === 200) {
      //               window.open(res.data.url)
      //             }
      //           })
      //           .finally(() => {
      //             this.loading = false
      //           })
      //       }
      //     } else {
      //       this.$message.error(res.msg)
      //       this.loading = false
      //     }
      //   })
      //   .catch(() => {
      //     this.loading = false
      //   })

      createOrderPayermax({
        returnUrl: '',
        channel: 7,
        chargeScene: '-1',
        goods: [{ goodsId: this.$route.query.goodsId, number: 1 }]
      })
        .then((res) => {
          if (res.code === 200) {
            if (res?.data?.url) location.href = res.data.url
          } else {
            this.$message.error(res.msg)
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">
  .stripe-main {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 750PX;
    margin: 0 auto;
    .content {
      width: 600px;
      max-width: 600PX;
      margin: 0 auto;
    }
    .button {
      width: 100%;
      margin: 0 auto;
      // min-width: 500PX;
      max-width: 750PX;
      height: 55PX;
      background: #ffc439;
      color: black;
      border-radius: 4PX;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20PX;
      cursor: pointer;
      &.loading {
        opacity: .8;
      }
    }

    @media only screen and (min-width: 150px) {
      .button {
        height: 25PX;
      }
    }

    @media only screen and (min-width: 200px) {
      .button {
        height: 35PX;
      }
    }

    @media only screen and (min-width: 375px) {
      .button {
        height: 45PX;
      }
    }

    @media only screen and (min-width: 625px) {
        .button {
          height: 55PX;
        }
    }
  }
</style>
